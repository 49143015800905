"use client";

import { getTokenName } from "@/lib/utils";
import { useAccount } from "wagmi";
import { useChainType } from "@/components/ChainTypeContext";
import { useBalanceMultichain } from "@/hooks/useBalanceMultichain";

const Balance = () => {
  const { chainType } = useChainType();
  const { chain } = useAccount();
  const formattedBalance = useBalanceMultichain();

  const token = getTokenName(chain, chainType);

  return (
    <div className="flex items-center justify-center p-2">
      <span className="sm:text-md text-nowrap text-lg font-bold">
        {formattedBalance} {token}
      </span>
    </div>
  );
};

export { Balance };
