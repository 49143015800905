import { useWallet } from "@civic/auth-web3/react";
import { useConnection } from "@solana/wallet-adapter-react";
import { useQuery } from "@tanstack/react-query";

export const useBalanceSol = () => {
  const { connection } = useConnection();
  const { wallet } = useWallet({ type: "solana" });

  // update the balance every 3 seconds
  return useQuery({
    queryKey: ["balance", wallet?.publicKey],
    queryFn: async () => {
      if (!wallet?.publicKey) return;
      const balance = await connection.getBalance(wallet.publicKey);
      return { value: BigInt(balance) };
    },
    enabled: !!wallet?.publicKey,
    refetchInterval: 3000,
  });
};
