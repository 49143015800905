"use client";
import {
  Card,
  CardContent,
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@civic/ui";
import { Balance } from "./balance";
import { Address } from "./address";
import { Sign } from "./sign";
import { Send } from "./send";
import { ChainSelector } from "./chain-selector";
import { CivicAuthIframeContainer, useUser } from "@civic/auth-web3/react";
import { useAutoConnect } from "@civic/auth-web3/wagmi";

import { useAccount } from "wagmi";
import { Airdrop } from "@/app/_components/airdrop";
import { Loader2 } from "lucide-react";
import { SignTypedData } from "@/app/_components/signTypedData";

import { useChainType } from "@/components/ChainTypeContext";
import { CivicPass } from "@/app/_components/civic-pass";
import { getTokenName } from "@/lib/utils";

function Web3U({
  walletCreationInProgress,
}: {
  walletCreationInProgress?: boolean;
}) {
  const { isConnected } = useAccount();
  const { chainType } = useChainType();
  const user = useUser();
  const isLoading = user.isLoading || walletCreationInProgress;
  const { chain } = useAccount();
  const token = getTokenName(chain, chainType);

  return (
    <>
      {!isConnected || isLoading ? (
        <div className="pointer-events-auto absolute inset-0 z-20 flex flex-col items-center justify-center rounded-3xl bg-white/50">
          <div>Connecting wallet...</div>
          <Loader2 className="mt-2 h-8 w-8 animate-spin" />
        </div>
      ) : null}
      <div
        className={`${!isConnected ? "pointer-events-none opacity-50" : ""} flex flex-col gap-4`}
      >
        <div className="flex flex-col items-center justify-between gap-2 sm:flex-row">
          <div>
            <ChainSelector />
          </div>

          <div className="flex flex-row">
            <Balance />
            <Airdrop />
          </div>
        </div>

        <div>
          <Address />
        </div>

        <div>
          <Tabs defaultValue="sign" className="flex flex-col">
            <TabsList className="overflow-x-auth flex justify-between overflow-y-hidden bg-transparent">
              <TabsTrigger
                value="sign"
                className="data-[state=active]:underline data-[state=active]:decoration-indigo-500 data-[state=active]:decoration-4 data-[state=active]:underline-offset-8 data-[state=active]:shadow-none"
              >
                Sign Message
              </TabsTrigger>
              {/*Disabled unless needed using className="hidden"*/}
              <TabsTrigger
                value="signTypedData"
                className="hidden data-[state=active]:underline data-[state=active]:decoration-indigo-500 data-[state=active]:decoration-4 data-[state=active]:underline-offset-8 data-[state=active]:shadow-none"
              >
                Sign Typed Data
              </TabsTrigger>
              <TabsTrigger
                value="send"
                className="data-[state=active]:underline data-[state=active]:decoration-indigo-500 data-[state=active]:decoration-4 data-[state=active]:underline-offset-8 data-[state=active]:shadow-none"
              >
                Send {token}
              </TabsTrigger>
              <TabsTrigger
                value="pass"
                className="data-[state=active]:underline data-[state=active]:decoration-indigo-500 data-[state=active]:decoration-4 data-[state=active]:underline-offset-8 data-[state=active]:shadow-none"
              >
                Civic Pass
              </TabsTrigger>
            </TabsList>
            <TabsContent value="sign" className="px-3 py-1.5">
              <Sign />
            </TabsContent>
            <TabsContent value="signTypedData" className="px-3 py-1.5">
              <SignTypedData />
            </TabsContent>
            <TabsContent value="send" className="px-3 py-1.5">
              <Send />
            </TabsContent>
            <TabsContent value="pass" className="px-3 py-1.5">
              <CivicPass />
            </TabsContent>
          </Tabs>
        </div>
      </div>
    </>
  );
}

function Web3Zone() {
  const { user, isLoading, walletCreationInProgress } = useUser();
  useAutoConnect();

  if (!isLoading && !user)
    return (
      
        <CivicAuthIframeContainer />
      
    );

  return (
    <Card className="z-10 min-h-96 w-full sm:w-[32rem]">
      <CardContent className="relative p-4 sm:p-10">
        <Web3U walletCreationInProgress={walletCreationInProgress} />
      </CardContent>
    </Card>
  );
}
export { Web3Zone };
