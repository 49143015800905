"use client";

import {
  Alert,
  AlertDescription,
  AlertTitle,
  Button,
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
  Input,
} from "@civic/ui";
import { AlertCircle, CheckCircle } from "lucide-react";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { useCallback, useEffect, useState } from "react";
import { Address, parseEther } from "viem";
import { useSendTransactionMultichain } from "@/hooks/useSendTransactionMultichain";
import { useChainType } from "@/components/ChainTypeContext";
import {
  Connection,
  LAMPORTS_PER_SOL,
  PublicKey,
  SystemProgram,
  Transaction,
} from "@solana/web3.js";
import { userHasWallet, Web3UserContextType } from "@civic/auth-web3";
import { useUser } from "@civic/auth-web3/react";
import { useConnection } from "@solana/wallet-adapter-react";

type BaseSchema = {
  address: string;
  amount: string;
};

const formSchemaEth = z.object({
  address: z.string().regex(/^0x[a-fA-F0-9]{40}$/, "Invalid Ethereum address"),
  amount: z
    .string()
    .refine((val) => !isNaN(Number(val)), "Amount must be a number"),
});

const formSchemaSol = z.object({
  address: z.string().refine((val) => {
    try {
      new PublicKey(val);
      return true;
    } catch {
      return false;
    }
  }, "Invalid Solana address"),
  amount: z
    .string()
    .refine((val) => !isNaN(Number(val)), "Amount must be a number"),
});

interface SendProps {
  onComplete?: () => void;
}

async function makeSolanaTransaction(
  userContext: Web3UserContextType,
  connection: Connection,
  values: BaseSchema,
) {
  if (!userHasWallet(userContext) || !userContext.solana.wallet.publicKey) {
    throw new Error("No wallet found");
  }

  const blockhash = await connection.getLatestBlockhash();

  // start with a simpler legacy transaction
  return new Transaction({
    ...blockhash,
    feePayer: userContext.solana.wallet.publicKey,
  }).add(
    SystemProgram.transfer({
      fromPubkey: userContext.solana.wallet.publicKey,
      toPubkey: new PublicKey(values.address),
      lamports: parseFloat(values.amount) * LAMPORTS_PER_SOL,
    }),
  );
}

const Send = ({ onComplete }: SendProps) => {
  const { connection } = useConnection();
  const {
    data,
    error: txSendError,
    sendTransaction,
  } = useSendTransactionMultichain();
  const [txPreparationError, setTxPreparationError] = useState<Error | null>(
    null,
  );
  const [isLoading, setLoading] = useState(false);
  const { chainType } = useChainType();
  const userContext = useUser();

  const error = txPreparationError ?? txSendError;
  const formSchema = chainType === "ethereum" ? formSchemaEth : formSchemaSol;

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    mode: "onChange",
    defaultValues: {
      address: "",
      amount: "",
    },
  });

  // revalidate form when chainType changes
  useEffect(() => {
    form.trigger();
  }, [chainType]);

  const makeTx = useCallback(
    async (
      values: z.infer<typeof formSchema>,
    ): Promise<
      | Transaction
      | {
          to: Address;
          value: bigint;
        }
    > => {
      if (chainType === "ethereum") {
        return {
          to: values.address as Address,
          value: parseEther(values.amount, "wei"),
        };
      }

      return makeSolanaTransaction(userContext, connection, values);
    },
    [userContext, chainType],
  );

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    if (!/^(-?)([0-9]*)\.?([0-9]*)$/.test(values.amount)) {
      form.setError("amount", {
        type: "invalid",
        message: "Invalid amount",
      });

      return;
    }

    setLoading(true);

    const tx = await makeTx(values).catch((error) => {
      setTxPreparationError(error);
    });
    if (tx) {
      sendTransaction(tx, {
        onSettled: () => {
          setLoading(false);
          onComplete?.();
        },
      });
    }
  };

  return (
    <div className="flex flex-col gap-4">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="flex w-full flex-col gap-2">
            <div className="flex-1">
              <FormField
                control={form.control}
                name="address"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        required
                        placeholder="Recipient's address"
                        className={
                          form.formState.errors.address
                            ? "border border-red-400"
                            : "border"
                        }
                        {...field}
                      />
                    </FormControl>

                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>

            <div className="flex-1">
              <FormField
                control={form.control}
                name="amount"
                render={({ field }) => (
                  <FormItem>
                    <FormControl>
                      <Input
                        required
                        placeholder="Amount"
                        className={
                          form.formState.errors.address
                            ? "border border-red-400"
                            : "border"
                        }
                        endSlot={
                          <Button size="xs" disabled={isLoading} variant="">
                            Send
                          </Button>
                        }
                        {...field}
                      />
                    </FormControl>

                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
          </div>
        </form>
      </Form>

      {data ? (
        <Alert>
          <CheckCircle className="h-4 w-4" />
          <AlertTitle>Transaction Complete</AlertTitle>
          <AlertDescription className="break-words">{data}</AlertDescription>
        </Alert>
      ) : null}

      {error ? (
        <Alert variant="destructive">
          <AlertCircle className="h-4 w-4" />
          <AlertTitle>Error</AlertTitle>
          <AlertDescription className="break-words">
            {error.message}
          </AlertDescription>
        </Alert>
      ) : null}
    </div>
  );
};

export { Send };
