import { useUser } from "@civic/auth-web3/react";
import { userHasWallet } from "@civic/auth-web3";
import { useMutation, UseMutationOptions } from "@tanstack/react-query";
import { useConnection } from "@solana/wallet-adapter-react";
import type {
  Transaction,
  TransactionSignature,
  VersionedTransaction,
} from "@solana/web3.js";
import { SendTransactionOptions } from "@solana/wallet-adapter-base";

type SendSolTxArgs = {
  transaction: Transaction | VersionedTransaction;
  sendTxOptions?: SendTransactionOptions;
};

type SendSolTxCallbacks = UseMutationOptions<
  TransactionSignature, // success type
  Error, // error type
  SendSolTxArgs // variables for mutate
>;

export const useSendTransactionSol = () => {
  const { connection } = useConnection();
  const userContext = useUser();
  const wallet = userHasWallet(userContext)
    ? userContext.solana.wallet
    : undefined;

  const sendTransactionFn = async ({
    transaction,
    sendTxOptions,
  }: SendSolTxArgs) => {
    if (!wallet) throw new Error("No wallet found");
    return wallet.sendTransaction(transaction, connection, sendTxOptions);
  };

  // create a react query mutation that calls wallet.sendTransaction
  const { mutate, ...rest } = useMutation<
    TransactionSignature,
    Error,
    SendSolTxArgs
  >({
    mutationFn: sendTransactionFn,
  });

  return {
    sendTransaction: (
      transaction: Transaction | VersionedTransaction,
      {
        sendTxOptions,
        onSuccess,
        onError,
        onSettled,
      }: {
        sendTxOptions?: SendTransactionOptions;
        onSuccess?: SendSolTxCallbacks["onSuccess"];
        onError?: SendSolTxCallbacks["onError"];
        onSettled?: SendSolTxCallbacks["onSettled"];
      } = {},
    ) =>
      mutate({ transaction, sendTxOptions }, { onSuccess, onError, onSettled }),
    ...rest,
  };
};
