"use client";

import { Alert, AlertDescription, AlertTitle, Button } from "@civic/ui";
import { AlertCircle, CheckCircle } from "lucide-react";
import { useSignTypedData } from "wagmi";
import { useState } from "react";

const SignTypedData = () => {
  const { data, error, signTypedData } = useSignTypedData();
  const [isLoading, setLoading] = useState(false);

  const onSubmit = () => {
    setLoading(true);

    signTypedData(
      {
        types: {
          Person: [
            { name: "name", type: "string" },
            { name: "wallet", type: "address" },
          ],
          Mail: [
            { name: "from", type: "Person" },
            { name: "to", type: "Person" },
            { name: "contents", type: "string" },
          ],
        },
        domain: {
            name: "Ether Mail",
            version: "1",
            chainId: 1,
            verifyingContract: "0xCcCCccccCCCCcCCCCCCcCcCccCcCCCcCcccccccC",
        },
        primaryType: "Mail",
        message: {
          from: {
            name: "Cow",
            wallet: "0xCD2a3d9F938E13CD947Ec05AbC7FE734Df8DD826",
          },
          to: {
            name: "Bob",
            wallet: "0xbBbBBBBbbBBBbbbBbbBbbbbBBbBbbbbBbBbbBBbB",
          },
          contents: "Hello, Bob!",
        },
      },
      {
        onSettled: () => {
          setLoading(false);
        },
      },
    );
  };

  return (
    <div className="flex flex-col gap-4">
      <Button disabled={isLoading} onClick={onSubmit}>
        Sign Typed Data
      </Button>

      {data ? (
        <Alert>
          <CheckCircle className="h-4 w-4" />
          <AlertTitle>Typed Data Signed</AlertTitle>
          <AlertDescription className="break-words">{JSON.stringify(data)}</AlertDescription>
        </Alert>
      ) : null}

      {error ? (
        <Alert variant="destructive">
          <AlertCircle className="h-4 w-4" />
          <AlertTitle>Error</AlertTitle>
          <AlertDescription className="break-words">
            {error.message}
          </AlertDescription>
        </Alert>
      ) : null}
    </div>
  );
};

export { SignTypedData };
