import { useSignMessage as useSignMessageEth } from "wagmi";
import { useChainType } from "@/components/ChainTypeContext";
import { useSignMessageSol } from "@/hooks/sol/useSignMessageSol";

export const useSignMessageMultichain = () => {
  const { chainType } = useChainType();
  const signMessageEth = useSignMessageEth();
  const signMessageSol = useSignMessageSol();
  return chainType === "ethereum" ? signMessageEth : signMessageSol;
};
