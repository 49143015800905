import { useSendTransaction as useSendTransactionEth } from "wagmi";
import { useChainType } from "@/components/ChainTypeContext";
import { useSendTransactionSol } from "@/hooks/sol/useSendTransactionSol";
import {
  Transaction as SolanaLegacyTransaction,
  VersionedTransaction as SolanaVersionedTransaction,
} from "@solana/web3.js";

type SolanaTransaction = SolanaLegacyTransaction | SolanaVersionedTransaction;
type EthTransaction = Parameters<
  ReturnType<typeof useSendTransactionEth>["sendTransaction"]
>[0];
type EthOpts = Parameters<
  ReturnType<typeof useSendTransactionEth>["sendTransaction"]
>[1];
type SolOpts = Parameters<
  ReturnType<typeof useSendTransactionSol>["sendTransaction"]
>[1];

type InferTransactionOpts<T> = T extends EthTransaction
  ? EthOpts
  : T extends SolanaTransaction
    ? SolOpts
    : never;

export const useSendTransactionMultichain = () => {
  const { chainType } = useChainType(); // returns 'solana' or 'ethereum'

  const sendTransactionEth = useSendTransactionEth();
  const sendTransactionSol = useSendTransactionSol();

  const sendTransactionFn = <T extends EthTransaction | SolanaTransaction>(
    vars: T,
    opts: InferTransactionOpts<T>,
  ) => {
    if (chainType === "ethereum") {
      return sendTransactionEth.sendTransaction(
        vars as EthTransaction,
        opts as EthOpts,
      );
    }
    return sendTransactionSol.sendTransaction(
      vars as SolanaTransaction,
      opts as SolOpts,
    );
  };
  const activeHook =
    chainType === "ethereum" ? sendTransactionEth : sendTransactionSol;

  return {
    ...activeHook,
    sendTransaction: sendTransactionFn,
  };
};
