import { GATEKEEPER_NETWORKS, GatekeeperNetworkType } from "@/lib/pass";
import { Pass } from "@/app/_components/pass";
import { GatewayProvider as EthGatewayProvider } from "@civic/ethereum-gateway-react";
import { GatewayProvider as SolGatewayProvider } from "@civic/solana-gateway-react";
import { useEthersWallet } from "@/hooks/useEthersWallet";
import { useState } from "react";
import { useChainType } from "@/components/ChainTypeContext";
import { useWallet } from "@civic/auth-web3/react";
import { PublicKey } from "@solana/web3.js";
import { useConnection } from "@solana/wallet-adapter-react";

export const CivicPass = () => {
  const { connection } = useConnection();
  const { chainType } = useChainType();
  const ethWallet = useEthersWallet();
  const { wallet: solWallet } = useWallet({ type: "solana" });
  const [gatekeeperNetwork, setGatekeeperNetwork] =
    useState<GatekeeperNetworkType>("Captcha");

  return chainType === "ethereum" ? (
    <EthGatewayProvider
      gatekeeperNetwork={GATEKEEPER_NETWORKS[gatekeeperNetwork]}
      wallet={ethWallet}
      options={{
        hideWalletPrompts: true,
      }}
    >
      <Pass
        gatekeeperNetwork={gatekeeperNetwork}
        setGatekeeperNetwork={setGatekeeperNetwork}
      />
    </EthGatewayProvider>
  ) : (
    <SolGatewayProvider
      connection={connection}
      cluster={"devnet"}
      gatekeeperNetwork={new PublicKey(GATEKEEPER_NETWORKS[gatekeeperNetwork])}
      wallet={solWallet}
      options={{
        hideWalletPrompts: true,
      }}
    >
      <Pass
        gatekeeperNetwork={gatekeeperNetwork}
        setGatekeeperNetwork={setGatekeeperNetwork}
      />
    </SolGatewayProvider>
  );
};
