"use client";

import { useEffect, useState, useTransition } from "react";
import Image from "next/image";
import { airdropEth } from "@/actions/airdropEth";
import { airdropSol } from "@/actions/airdropSol";
import { Button } from "@/components/ui/button";
import { Ellipsis } from "lucide-react";
import { userHasWallet } from "@civic/auth-web3";
import { useUser } from "@civic/auth-web3/react";
import airdropLogo from "@/assets/airdrop.svg";
import { useAccount } from "wagmi";
import { baseSepolia } from "viem/chains";
import { useChainType } from "@/components/ChainTypeContext";
import { Address } from "viem";
import { PublicKey } from "@solana/web3.js";
import {
  ethRecipientIsEligibleForAirdrop,
  solRecipientIsEligibleForAirdrop,
} from "@/lib/airdropUtils";
import { useConnection } from "@solana/wallet-adapter-react";

interface AirdropProps {
  onComplete?: () => void;
}

const supportedAirdropChainIds: number[] = [
  baseSepolia.id,
  -1 /* use -1 to reference solana devnet */,
];

const chainIsSupported = (chainId: number | undefined) =>
  chainId && supportedAirdropChainIds.includes(chainId);

const useIsAirdropEnabled = () => {
  const { chainType } = useChainType();
  const userContext = useUser();
  const [isEligible, setIsEligible] = useState(false);
  const { chain } = useAccount();
  const { connection } = useConnection();

  useEffect(() => {
    const chainId = chainType === "ethereum" ? chain?.id : -1;
    (async () => {
      if (userHasWallet(userContext) && chainIsSupported(chainId)) {
        const recipientIsEligibleForAirdrop =
          chainType === "ethereum"
            ? await ethRecipientIsEligibleForAirdrop(
                userContext.ethereum.address as Address,
              )
            : await solRecipientIsEligibleForAirdrop(
                new PublicKey(userContext.solana.address),
                connection,
              );
        setIsEligible(recipientIsEligibleForAirdrop);
        return;
      }
    })();
    setIsEligible(false);
  }, [setIsEligible, userContext, chain, chainType, connection]);

  return isEligible;
};

export function Airdrop({ onComplete }: AirdropProps) {
  const { chainType } = useChainType();
  const canAirdrop = useIsAirdropEnabled();
  const [isPending, startTransition] = useTransition();
  const userContext = useUser();
  const [result, setResult] = useState<{
    success: boolean;
    message: string;
  } | null>(null);

  useEffect(() => {
    if (result) onComplete?.();
  }, [result]);

  const handleClick = () => {
    setResult(null);
    startTransition(async () => {
      if (!userHasWallet(userContext)) return;

      let airdropResponse: { error?: string; hash?: string };
      try {
        if (chainType === "ethereum") {
          airdropResponse = await airdropEth(userContext.ethereum.address);
        } else {
          // THIS ONE IS NOT WORKING
          airdropResponse = await airdropSol(userContext.solana.address);
        }
        setResult({
          success: !!airdropResponse.hash,
          message: airdropResponse.error || "Airdrop done",
        });
      } catch (error) {
        console.log("error", error);
      }
    });
  };

  return (
    <div className="relative flex flex-none flex-col items-center justify-center">
      {isPending && (
        <div className="absolute inset-0 top-1/2 flex items-center justify-center rounded-full bg-background/50">
          <Ellipsis
            color="#7C75FF"
            strokeWidth={4}
            className="stroke-black-600 h-15 w-5 animate-bounce"
          />
        </div>
      )}
      <Button
        onClick={handleClick}
        disabled={isPending || !canAirdrop}
        className="rounded-full bg-transparent p-2 shadow-none hover:bg-transparent"
      >
        <Image
          src={airdropLogo}
          alt="Airdrop"
          width={24}
          height={24}
          className="bg-transparent"
        />

        <span className="sr-only">Initiate Airdrop</span>
      </Button>
      {/* 
      TODO: handle error case
      {result && (
        <p className={result.success ? "text-green-600" : "text-red-600"}>
          {result.message}
        </p>
      )} */}
    </div>
  );
}
