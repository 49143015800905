import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { Chain, formatEther } from "viem";
import { ChainType } from "@civic/auth-web3";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";

export const cn = (...inputs: ClassValue[]) => twMerge(clsx(inputs));

export const formatBalanceEth = (balance: bigint | undefined) => {
  if (!balance) return (0.0).toFixed(5);
  return Number.parseFloat(formatEther(balance)).toFixed(5);
};

export const formatBalanceSol = (balance: bigint | undefined) => {
  if (!balance) return (0.0).toFixed(5);
  return (Number.parseFloat(balance.toString()) / LAMPORTS_PER_SOL).toFixed(5);
};

const valueIsDefined = <K, V>(entry: [K, V | undefined]): entry is [K, V] =>
  entry[1] !== undefined;

export const trimUndefinedValues = (
  map: Record<string, string | undefined>,
): Record<string, string> =>
  Object.fromEntries(Object.entries(map).filter(valueIsDefined));

// Get the token name from viem, prepend with 't' for testnet if needed - default to ETH if no chain is selected yet
export const getTokenName = (
  currentChain: Chain | undefined,
  currentChainType: ChainType,
) => {
  if (currentChainType === "solana") return "tSOL";
  const testnetPrefix = currentChain?.testnet ? "t" : "";
  return testnetPrefix + (currentChain?.nativeCurrency?.symbol ?? "ETH");
};
