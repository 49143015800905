import { useChainType } from "@/components/ChainTypeContext";
import { useGateway as useGatewayEth } from "@civic/ethereum-gateway-react";
import { useGateway as useGatewaySol } from "@civic/solana-gateway-react";

// Each hook (useGatewayEth and useGatewaySol) will be running under the context of
export const useGatewayMultichain = () => {
  const { chainType } = useChainType();
  if (chainType === "ethereum") {
    return useGatewayEth();
  } else {
    return useGatewaySol();
  }
};
