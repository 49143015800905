"use client";
import { CivicAuthProvider as InternalCivicAuthProvider } from "@civic/auth/react";
import React, { type ReactNode, useCallback, useState } from "react";
import { Web3UserProvider } from "./Web3UserProvider.js";
import { type CivicAuthWeb3ProviderProps } from "../types.js";

// A wrapper around civic auth that injects an embedded wallet.
// TODO should be in the reactjs/ folder
export function CivicAuthProvider({
  children,
  ...props
}: CivicAuthWeb3ProviderProps & { children: ReactNode }) {
  // allow the embedded wallet to add a hook to the logout process, ensuring its state gets cleaned up
  // for security reasons, and to avoid bugs when changing users
  const [logoutCallback, setLogoutCallback] = useState<() => Promise<void>>();
  const registerLogout = useCallback(
    (callback: () => Promise<void>) => setLogoutCallback(() => callback),
    [setLogoutCallback],
  );

  return (
    <InternalCivicAuthProvider
      {...props}
      onSignOut={async () => {
        await logoutCallback?.();
      }}
    >
      <Web3UserProvider registerLogoutCallback={registerLogout} {...props}>
        {children}
      </Web3UserProvider>
    </InternalCivicAuthProvider>
  );
}
