import { GatekeeperNetworkType } from "@/lib/pass";
import { FC } from "react";
import { useGatewayMultichain } from "@/hooks/useGatewayMultichain";
import { GatewayStatus } from "@civic/ethereum-gateway-react";
import { Button } from "@civic/ui";
import { Loader2 } from "lucide-react";

interface PassStatusIndicatorProps {
  gatekeeperNetwork: GatekeeperNetworkType;
}

export const PassStatusIndicator: FC<PassStatusIndicatorProps> = ({
  gatekeeperNetwork,
}) => {
  const { gatewayStatus, requestGatewayToken } = useGatewayMultichain();

  switch (gatewayStatus) {
    case GatewayStatus.UNKNOWN:
    case GatewayStatus.CHECKING:
      return <Loader2 className="mr-2 h-4 w-4 animate-spin" />;
    case GatewayStatus.ACTIVE:
      return (
        <span className="text-center">
          You passed the Civic pass verification
        </span>
      );
    default:
      return (
        <div className={"flex flex-col gap-4"}>
          <p className="text-center">Try getting a Civic Pass verification:</p>
          <Button
            onClick={() => {
              if (!requestGatewayToken) {
                throw new Error(
                  "Gateway token request function not available.",
                );
              }

              requestGatewayToken();
            }}
          >
            {gatekeeperNetwork}
          </Button>
        </div>
      );
  }
};
