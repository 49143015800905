import { useUser } from "../components/Web3UserProvider.js";
import { userHasWallet } from "../lib/walletUtils.js";
import type { ChainType, Wallets } from "../types.js";

export const useWallet = <T extends ChainType>({
  type,
}: {
  type: T;
}): {
  address: Wallets[T]["address"] | undefined;
  wallet: Wallets[T]["wallet"] | undefined;
} => {
  const userContext = useUser();
  if (!userHasWallet(userContext)) {
    return {
      wallet: undefined,
      address: undefined,
    };
  }
  return userContext[type];
};
