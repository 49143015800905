import { Tabs, TabsList, TabsTrigger } from "@civic/ui";
import { GatekeeperNetworkType } from "@/lib/pass";
import { PassStatusIndicator } from "./pass-status-indicator";

interface PassProps {
  gatekeeperNetwork: GatekeeperNetworkType;
  setGatekeeperNetwork: (value: GatekeeperNetworkType) => void;
}

const Pass = ({ gatekeeperNetwork, setGatekeeperNetwork }: PassProps) => {
  return (
    <div className="flex flex-col items-center justify-center gap-4">
      <Tabs
        defaultValue={gatekeeperNetwork}
        className="flex flex-col"
        onValueChange={(value) =>
          setGatekeeperNetwork(value as GatekeeperNetworkType)
        }
      >
        <TabsList className="overflow-x-auth flex justify-between overflow-y-hidden bg-transparent">
          <TabsTrigger
            value={"Captcha"}
            className="data-[state=active]:underline data-[state=active]:decoration-indigo-500 data-[state=active]:decoration-4 data-[state=active]:underline-offset-8 data-[state=active]:shadow-none"
          >
            CAPTCHA
          </TabsTrigger>
          <TabsTrigger
            value={"Uniqueness"}
            className="data-[state=active]:underline data-[state=active]:decoration-indigo-500 data-[state=active]:decoration-4 data-[state=active]:underline-offset-8 data-[state=active]:shadow-none"
          >
            Uniqueness
          </TabsTrigger>
          <TabsTrigger
            value={"Liveness"}
            className="data-[state=active]:underline data-[state=active]:decoration-indigo-500 data-[state=active]:decoration-4 data-[state=active]:underline-offset-8 data-[state=active]:shadow-none"
          >
            Liveness
          </TabsTrigger>
        </TabsList>
        <div className="flex justify-center px-3 py-1.5">
          <PassStatusIndicator gatekeeperNetwork={gatekeeperNetwork} />
        </div>
      </Tabs>
    </div>
  );
};

export { Pass };
