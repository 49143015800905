import { printVersion } from "./shared/index.js";
printVersion();
export { isNewUser, userHasWallet } from "./lib/walletUtils.js";
export { CivicAuthProvider } from "./components/CivicAuthProvider.js";
export type {
  Wallets,
  NewWeb3UserContext,
  ExistingWeb3UserContext,
  Web3UserContextType,
  NonEmptyArray,
  SolanaWallet,
  ChainType,
} from "./types.js";

export { AuthStatus } from "@civic/auth";
export type {
  OAuthTokenTypes,
  CodeVerifier,
  UserStorage,
  CookieConfig,
  TokensCookieConfig,
  Tokens,
  Endpoints,
  Config,
  User,
  DisplayMode,
  ForwardedTokens,
  AuthStorage,
} from "@civic/auth";
export { VERSION } from "./shared/version.js";
export { isReady } from "./wagmi/lib/utils.js";
