import { createPublicClient, http } from "viem";
import { baseSepolia } from "viem/chains";
import { Connection, PublicKey } from "@solana/web3.js";

export const ethRecipientIsEligibleForAirdrop = async (
  recipient: `0x${string}`,
  rpc?: string,
): Promise<boolean> => {
  const publicClient = createPublicClient({
    chain: baseSepolia,
    transport: http(rpc),
  });

  // eligibility checks
  // TODO lookup the userinfo for the user and check the recipient address matches the wallet claim

  // ensure the tx count is 0 and balance is 0
  const recipientTransactionCount = await publicClient.getTransactionCount({
    address: recipient,
  });

  const recipientBalance = await publicClient.getBalance({
    address: recipient,
  });

  return recipientTransactionCount == 0 && recipientBalance == BigInt(0);
};

export const solRecipientIsEligibleForAirdrop = async (
  recipient: PublicKey,
  connection: Connection,
): Promise<boolean> => {
  // eligibility checks
  // TODO lookup the userinfo for the user and check the recipient address matches the wallet claim

  // ensure the tx count is 0 and balance is 0
  const getSignaturesForAddressPromise =
    connection.getSignaturesForAddress(recipient);
  const getBalancePromise = connection.getBalance(recipient);

  const recipientTransactions = await getSignaturesForAddressPromise;
  const recipientBalance = await getBalancePromise;

  return recipientTransactions.length == 0 && recipientBalance == 0;
};
