import { userHasWallet } from "@civic/auth-web3";
import { useUser } from "@civic/auth-web3/react";
import { useMutation } from "@tanstack/react-query";

export const useSignMessageSol = () => {
  const userContext = useUser();
  const wallet = userHasWallet(userContext)
    ? userContext.solana.wallet
    : undefined;

  const signMessageFn = async (message: string) => {
    if (!wallet) throw new Error("No wallet found");
    return wallet.signMessage(Buffer.from(message, "utf-8"));
  };

  // create a react query mutation that calls wallet.signMessage
  const { mutate: signMessage, ...rest } = useMutation<
    Uint8Array,
    Error,
    { message: string }
  >({
    mutationFn: ({ message }) => signMessageFn(message),
  });

  return {
    signMessage,
    ...rest,
  };
};
