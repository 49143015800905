import { useAccount, useBalance } from "wagmi";
import { useChainType } from "@/components/ChainTypeContext";
import { formatBalanceEth, formatBalanceSol } from "@/lib/utils";
import { useBalanceSol } from "@/hooks/sol/useBalanceSol";

export const useBalanceMultichain = () => {
  const { address } = useAccount();
  const { chainType } = useChainType();
  const ethBalance = useBalance({
    address,
    query: {
      refetchInterval: 3000,
    },
  });
  const solBalance = useBalanceSol();
  return chainType === "ethereum"
    ? formatBalanceEth(ethBalance?.data?.value)
    : formatBalanceSol(solBalance?.data?.value);
};
