"use client";

import { useCopyToClipboard } from "@/hooks/useCopyToClipboard";
import { toast } from "@civic/ui";
import { Copy } from "lucide-react";
import { useChainType } from "@/components/ChainTypeContext";
import { useWallet } from "@civic/auth-web3/react";

const Address = () => {
  const { chainType } = useChainType();
  const { address } = useWallet({ type: chainType });

  const [, copyToClipboard] = useCopyToClipboard();

  const handleCopyAddress = () => {
    if (!address) return;
    copyToClipboard(address);

    toast({
      title: "Address copied to clipboard",
      variant: "default",
    });
  };

  return (
    <div className="flex items-center justify-center p-2">
      <span className="truncate font-mono text-sm">{address}</span>{" "}
      {address && (
        <Copy
          className="ml-2 h-4 w-4 shrink-0 cursor-pointer text-neutral-400"
          onClick={handleCopyAddress}
        />
      )}
    </div>
  );
};

export { Address };
