"use client";

import { useAccount, useSwitchChain } from "wagmi";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@civic/ui";
import baseLogo from "@/assets/base.svg";
import polygonLogo from "@/assets/polygon.svg";
import sepoliaLogo from "@/assets/sepolia.svg";
import solanaLogo from "@/assets/solana.svg";
import Image from "next/image";
import { baseSepolia, polygonAmoy, sepolia } from "viem/chains";
import { useChainType } from "@/components/ChainTypeContext";
import { useEffect } from "react";

const getEthChainLogo = (chainId: number) => {
  if (chainId === baseSepolia.id) return baseLogo;
  if (chainId === polygonAmoy.id) return polygonLogo;
  if (chainId === sepolia.id) return sepoliaLogo;

  return null;
};

const ChainSelector = () => {
  const { chainType, setChainType } = useChainType();
  const { chainId: ethChainId, connector } = useAccount();
  const { chains, switchChain } = useSwitchChain();

  // Support EVM chains and Solana Devnet
  const selectableChains = chains.map((c) => ({
    id: c.id,
    type: "ethereum",
    name: c.name,
    logo: getEthChainLogo(c.id),
  }));

  selectableChains.push({
    id: -1,
    type: "solana",
    name: "Solana Devnet",
    logo: solanaLogo,
  });

  const selectedChainId = chainType === "solana" ? -1 : ethChainId;
  const selectedChain = selectableChains.find(
    (chain) => chain.id === selectedChainId,
  );

  useEffect(() => {
    if (chainType === "ethereum" && !selectedChain) {
      switchChain({
        chainId: baseSepolia.id,
      });
    }
  }, [chainType, selectedChain, connector]);

  return (
    <Select
      value={`${selectableChains.find((chain) => chain.id === selectedChainId)?.id}`}
      onValueChange={(c) => {
        // do nothing if the chain is already selected
        if (`${selectedChainId}` === c) return;
        // switch the overall app to solana mode if the user selects the solana chain
        if (parseInt(c) === -1) {
          setChainType("solana");
        } else if (chainType === "solana") {
          // switch the overall app to ethereum mode if the user selects an ethereum chain
          setChainType("ethereum");
        } else {
          // in ethereum mode, switch the ethereum chain
          switchChain({
            chainId: parseInt(c),
          });
        }
      }}
    >
      <SelectTrigger className="w-[240px]">
        <SelectValue placeholder="Select a Chain" />
      </SelectTrigger>
      <SelectContent>
        {selectableChains.map((chain) => (
          <SelectItem key={chain.name} value={`${chain.id}`}>
            <div className="flex items-center gap-2">
              {chain.logo ? (
                <Image src={chain.logo} alt={chain.name} className="h-4 w-4" />
              ) : null}{" "}
              {chain.name}
            </div>
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};

export { ChainSelector };
